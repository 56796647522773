$background: rgba(255,255,255, 0);
$easing: cubic-bezier(.25,1.7,.35,.8);
$open-duration: 1s;
$arrow-color: #60aff0;

.root {
  position: relative;
  min-height: 100vh;
}
.arrow-icon {
  background-color: $background;
  margin: auto;
  width: 65px;
  display: block;
  margin: 1em auto;
  position: relative;
  top: -10px;
  border-radius: 4px;
  -moz-box-shadow:    0px 2px 3px 0px #666;
  -webkit-box-shadow: 0px 2px 3px 0px #666;
  box-shadow:         0px 2px 3px 0px #666;
}

.count {
  font-size: 2em;
}

.counter {
  position: absolute;
  right: 20px;
  top: -100px;
  height: 80px;
  border-radius: 80px;
  width: 80px;
  background-color: rgba(109, 128, 139, 0.89);
  color: white;
  font-family: 'Quicksand';
  font-size: 0.7em;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#footer {
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 0;
}

.header {
  background-color: #fff;
  width: 100%;
  position: fixed;
  border-bottom: 1px solid #DDD;
}

.header-content {
  background-color: #fff;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  height: 100px;
  width: 95%;
}

.left-bar {
  position: absolute;
  background-color: transparent;
  top: 0;
  left: 0;
  width: 30px;
  height: 7px;
  display: block;
  transform: rotate(35deg);
  float: right;
  border-radius: 2px;
  &:after {
    content:"";
    background-color: $arrow-color;
    width: 30px;
    height: 7px;
    display: block;
    float: right;
    border-radius: 6px 10px 10px 6px;
    transition: all $open-duration ease;
    z-index: -1;
  }
}

.map {
  position: absolute;
  z-index: -99;
}

.map-400 {
  top: -100px;
  animation : shorten $open-duration ease;
}

.map-800 {
  top: 100px;
  animation : lengthen $open-duration ease;
}

.map-object {
  width: 100%;
}

.open {
  .left-bar:after {
    transform-origin: center center;
    transform: rotate(-70deg);
  }
.right-bar:after {
    transform-origin: center center;
    transform: rotate(70deg);
  }
}

.page {
  position: absolute;
  border-top: 1px solid #DDD;
  background-color: #fff;
  width: 100%;
}

.page-400 {
  top: 500px;
  animation: move-up $open-duration ease;
}

.page-800 {
  top: 800px;
  animation: move-down $open-duration ease;
}

.resize-button {
  height: 40px;
  background-color: rgba(255, 255, 255, 0);
  border: none;
}

.resize-button-area-400 {
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 200px;
  animation: resize-move-up $open-duration ease;
}

.resize-button-area-800 {
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 100px;
  animation: resize-move-down $open-duration ease;
}

.right-bar {
  position: absolute;
  background-color: transparent;
  top: 0px;
  left: 19px;
  width: 30px;
  height: 7px;
  display: block;
  transform: rotate(-35deg);
  float: right;
  border-radius: 2px;
  &:after {
    content:"";
    background-color: $arrow-color;
    width: 30px;
    height: 7px;
    display: block;
    float: right;
    border-radius: 10px 6px 6px 10px;
    transition: all $open-duration ease;
    z-index: -1;
  }
}

.searchbar {
  width: 150px;
  height: 16px;
  padding: 5px;
  border: 1px solid #555;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 16px;
}

.searchbar-icon {
  color: #555;
  width: 80%;
  height: 80%;
}

.searchbar-input {
  border: none;
  width: 80%;
  margin: auto;
}

.title {
  font-weight: bold;
  margin-top: -2px;
  margin-bottom: -2px;
  font-family: 'Quicksand';
  font-size: 18pt;
  color: #5D7D91;
}

@keyframes lengthen {
  from {top: -100px;}
  to {top: 100px;}
}

@keyframes shorten {
  from {top: 100px;}
  to {top: -100px;}
}

@keyframes move-down {
  from {top: 500px;}
  to {top: 800px;}
}

@keyframes move-up {
  from {top: 800px;}
  to {top: 500px;}
}

@keyframes resize-move-down {
  from {bottom: 200px;}
  to {bottom: 100px;}
}

@keyframes resize-move-up {
  from {bottom: 100px;}
  to {bottom: 200px;}
}